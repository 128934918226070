@font-face {
    font-family: "Hanken-Grotesk";
    font-style: normal;
    font-weight: 300;
    src: url('hk.ttf') format('truetype');
  }
  body {
    font-family: "Hanken-Grotesk" !important;
  }

html {
     
    .pt-1 {
        padding-top: .25rem;
    }

    .aqua-vida-bg {
        background-color: #36DCBB;
    }

    .blue-viajes-bg {
        background-color: #02405A;
    }

    .aqua-vida {
        color: #36DCBB;
    }

    .blue-viajes {
        color: #02405A;
    }
}

